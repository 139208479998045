import React from 'react'
import PropTypes from 'prop-types'
import Img from '../../../shared/Img'

const DebtReliefPartners = ({ title, text }) => (
  <div
    className="w-block text-center text-sm font-normal leading-snug tracking-normal text-gray-900 lg:max-w-screen-lg"
    id="debt-relief-partners"
  >
    <h2 className="mb-3 text-center text-2xl font-normal leading-tight tracking-normal text-blue-700 md:text-4xl md:leading-10">
      {title}
    </h2>
    <p className="text-lg">{text}</p>
    <div className="m-auto flex flex-col items-center justify-center md:flex-row">
      <div className="my-5 px-5">
        <Img
          src="/next-assets/talk-icon.png"
          alt="talk icon"
          className="m-auto mb-3 h-16"
          styles={{ position: 'relative', height: '64px' }}
        />
        <strong className="text-s text-blue-700">
          TALK TO US – WE’RE HERE TO HELP
        </strong>
        <p className="mt-5 text-sm">
          Our customer service representatives are friendly people who are
          always ready to answer your questions or concerns
        </p>
      </div>
      <div className="my-5 px-5">
        <Img
          src="/next-assets/map-icon.png"
          alt="map icon"
          className="m-auto mb-3 h-16"
          styles={{ position: 'relative', height: '64px' }}
        />
        <strong className="text-s text-red-600">
          TRACK YOUR PROGRESS 24/7
        </strong>
        <p className="mt-5 text-sm">
          Your online Client Dashboard makes it easy to stay up to date on your
          debt relief program status
        </p>
      </div>
    </div>
  </div>
)

DebtReliefPartners.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default DebtReliefPartners
